import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { AppFrame } from 'scala'
import { useRouter } from 'next/router'
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Context } from '../../context'
import { trigger } from '../../lib/events'
import { useDesktopApp } from '../../../../../packages/web-app-shell/src/hooks/misc/use-desktop-app'

interface AuthenticatedContainerType {
  children: ReactElement
}

export const AppFrameWrapper: React.FC<AuthenticatedContainerType> = ({
  children
}) => {
  const { i18n } = useLingui()
  const { pathname, push } = useRouter()
  const { userToken } = useContext(Context)
  const { isDesktopApp } = useDesktopApp()

  const [campaignGlobalVerified, setCampaignGlobalVerified] = useState(false)

  useEffect(() => {
    if (userToken && !campaignGlobalVerified) {
      setCampaignGlobalVerified(true)
    }
  }, [userToken, campaignGlobalVerified])

  const strings = useMemo(() => {
    const extraLabels = !isDesktopApp
      ? { 'actions.downloadDesktopApp': i18n._(t`get_desktop_app`) }
      : {}

    return {
      'label.beta': i18n._(t`beta`),
      'header.state.loadingFailed': i18n._(t`task.list.state.failed`),
      'account.labelPremium': i18n._(t`label.premium`),
      'label.support': i18n._(t`label.support`),
      unlock_all_features: i18n._(t`unlock_all_features`),
      manageSubscription: i18n._(t`manageSubscription`),
      'label.shortcuts': i18n._(t`keyboard_shortcuts`),
      'account.settings': i18n._(t`header.nav.user.settings`),
      'account.premium': i18n._(t`header.nav.user.getpremium`),
      'account.signOut': i18n._(t`header.nav.user.logout`),
      'actions.getapp': i18n._(t`actions.getapp`),
      ...extraLabels
    }
  }, [i18n, isDesktopApp])

  const handleNavigation = useCallback(
    (path: string) => {
      switch (path) {
        case '/support':
          window.open(path, '_blank', 'noopener')
          break
        case '/logout':
          trigger('purge:tasks')
          push(path)
          break
        case '/get-desktop-app':
          window.open('https://desktop.moises.ai/', '_blank', 'noopener')
          break
        default:
          push(path)
          break
      }
    },
    [push]
  )

  const navItems = useMemo(
    () =>
      [
        {
          label: i18n._(t`label.library`),
          id: 'library_tab_button',
          link: '/library',
          active: pathname === '/library' || pathname.includes('/playlist')
        },
        {
          label: i18n._(t`lyric_writer`),
          id: 'lyric_writer_tab_button',
          link: '/lyric-writer',
          active: pathname === '/lyric-writer'
        },
        {
          label: 'Voice Studio',
          id: 'daw_tab_button',
          link: '/voice2',
          active: pathname === '/voice2'
        }
      ].filter((item) => !!item),
    [i18n, pathname]
  )

  return (
    <AppFrame
      strings={strings}
      navItems={navItems}
      onNavigate={handleNavigation}
      currentPathName={pathname}
    >
      {children}
    </AppFrame>
  )
}
